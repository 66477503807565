import React from 'react';
import { PathService } from '../../services';
import { TemplateProps, CareerOpeningViewContext } from '../../types';
import { CareerOpeningView } from '../../views';

const CareerOpening: React.FunctionComponent<
  TemplateProps<CareerOpeningViewContext>
> = ({ location, pageContext }) => {
  PathService.set(location.pathname);
  return <CareerOpeningView job={JSON.parse(pageContext.job)} />;
};

export default CareerOpening;
